.calendarStyle {
  border: 0px !important;
  min-height: 350px;
  padding-bottom: 3.75rem;
}

.calendarContainer {
  min-height: 450px;
}

.navContainer {
  padding-bottom: 4.5rem;
}

.navButton {
}

.textStyle {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    background: theme("colors.buttonColor.primary");
    bottom: 0;
    width: 100%;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-in-out;
  }
}

.active {
  position: relative;

  &:hover {
    .textStyle {
      &::after {
        transform-origin: left;

        transform: scaleX(1);
      }
    }
  }
}
