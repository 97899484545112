.thumbnails {
  @apply pt-16;

  @screen lg {
    padding-top: 120px;
  }
}

.titleImage {
  padding-top: 100%;
  @screen lg {
    top: var(--header-height);
    padding-top: calc((672/560) * 100%);
  }
}

.columnCrossContentBlock {}

.crossContentBlockCard {
  // width: calc((6 / 7) * 100vw - 48px);
  flex: 0 0 calc((6 / 7) * 100vw - 48px);
  max-width: 320px;

  @screen lg {
    width: 100%;
    max-width: unset;
  }
}

.darkBgPattern {
  z-index: 1;
  position: absolute;
  inset: 0;
  background-repeat: repeat;
  background-size: 500px;
  background-position: center center;
  // background-size: cover;
}