.livecamsBlock {
  height: 100vh;
  max-height: 800px;
}

.livecamsBg {
}

.livecamSlide {
  padding-left: 0;
  // &.livecamSlideCurrent {
  //   .livecamSlideParallaxLayer {
  //     transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  //   }
  // }
  // &.livecamSlideNext {
  //   .livecamSlideParallaxLayer {
  //   }
  // }
  // &.livecamSlidePrev {
  //   .livecamSlideParallaxLayer {
  //     transition: transform 1s;
  //   }
  // }
}
.livecamSlideParallaxLayer {
  transform: scale(1.2);
}

.livecamsBlockExplore {
  path:nth-child(2) {
    fill: theme('colors.text.h1');
  }
}

.todaysDate {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
}

:global(.ete) {
  .todaysMonthYear {
    color: #fff;
  }
}
.livecamsBlockFreshData {
  > div, a {
    &:first-child {
      @screen xl {
        flex: 0 0 calc(16.6% - 12px);
      }
    }
    &:nth-child(2) {
      @screen xl {
        flex: 0 0 calc(25% - 6px);
      }
    }
    &:nth-child(3) {
      @screen xl {
        flex: 0 0 calc(31% - 12px);
      }
    }
    &:nth-child(4) {
      @screen xl {
        flex: 0 0 calc(27.4% - 6px);
      }
    }
  }
}

.livecamsSectorsSelect {
  @apply truncate;
  transform: translateY(-3px);
  font-size: 20px;
  line-height: 24px;
  max-width: 220px;
}
.trailsLastUpdated {
  vertical-align: bottom;
  
  p {
    max-width: 120px;
    transform: translateY(2px);
    line-height: 30px;
  }
}


.livecamsMeteoWidget {
  th {
    padding: theme('spacing.2') 0;
  }
}
.livecamsSnowWidget {
  th {
    padding: theme('spacing.2') 0;
  }
  td {
    padding: 6px 0;
  }
}
.livecamsTrailsWidget {
th {
    padding: theme('spacing.2') 0;
  }
  td {
    &:not(.trailsLastUpdated) {
      padding: 6px 6px 6px 0;
    }
  }
}