.multiListBlock {
  contain: paint;
  & + div {
    // Handle the beige relief overflow
    // Overflow hidden here would break the sticky behavior
    z-index: 1;
  }
  &.multiListBlockImages {
    .multiListBlockContainer {
      padding-top: 90px;

      @screen lg {
        padding-top: 120px;
      }
    }

    .multiListBlockSwitcher {
      @screen lg {
        margin-top: 200px;
      }
    }

    .multilistListWrapper {
      padding-right: 0;
      overflow: hidden;
    }
  }
}

.backgroundDark {
  background-color: theme("colors.text.h1");
  color: theme("colors.white");
}

.stickyAside {
  @screen lg {
    top: calc(var(--header-height) + theme("spacing.8"));
  }
}

.darkBgPattern {
  z-index: 1;
  position: absolute;
  inset: 0;
  background-repeat: repeat;
  background-size: 500px;
  background-position: center center;
  // background-size: cover;
}

.darBgRelief {
  @screen lg {
    margin-top: -140px;
  }
}

.beigeRelief {
  bottom: 48%;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -300%;
    width: 100%;
    height: 300%;
    background-color: #f5f2ee;
    z-index: 1;
  }
}
