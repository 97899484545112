.cardChampion {
  @screen lg {
    width: 100%;
    max-width: unset;
  }
}

.cardImage {
  padding-top: calc((208/327) * 100%);

  @screen lg {
    padding-top: calc((552/445) * 100%);
  }
}