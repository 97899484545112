.linkStyle {
  &:nth-of-type(3n) {
    .cardCalendar {
      border-right: 0;
    }
  }
  &:nth-of-type(1) {
    .cardCalendar {
      border-top: 1;
      @media (min-width: 1024px) {
        border-top: 0;
      }
    }
  }

  &:nth-of-type(2) {
    .cardCalendar {
      border-top: 1;
      @media (min-width: 1024px) {
        border-top: 0;
      }
    }
  }

  &:nth-of-type(3) {
    .cardCalendar {
      border-top: 1;
      @media (min-width: 1024px) {
        border-top: 0;
      }
    }
  }
  .cardCalendar {
    transition: background-color 0.2s ease-in-out;

    &:nth-of-type() {
    }
  }

  .ratioImage {
    aspect-ratio: 315 / 184;
  }

  .overlay {
    font-size: 0.75rem;
  }

  .imageContainer {
    aspect-ratio: 315 / 184;
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      border-radius: 8px;
    }
  }
}
