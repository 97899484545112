.pushBlock {}  


.pushCard {
  @apply flex flex-col self-start justify-end p-6 lg:px-8 text-white rounded-lg overflow-hidden;
  width: calc(100vw - 48px);
  height: 400px;
  transition: width .3s, height .3s;

  &:not(:last-child) {
    margin-right: 12px;

    @screen lg {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  &.pushCardLarger {
    
    @screen lg {
      width: calc(41.6% - 8px);
      height: 550px;
    }

    .pushCardContent {
      max-height: initial;
      opacity: 1;
    }
  }
  &.pushCardSmaller {
    
    @screen lg {
      width: calc(25% - 8px);
    }
  }
  &.pushCardSMedium {
    
    @screen lg {
      width: calc(33.3% - 8px);
    }
  }

  svg {
    path:nth-child(2) {
      fill: theme('colors.text.h1');
    }
  }

  @screen lg {
    width: calc(33.3% - 8px);
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
.smallWeatherIcon {
  width: 72px;
  height: 72px;
}

.pushCardContent {
  max-height: 200px;
  transition: opacity .3s;
}

.pushBlockContainer {
  @apply overflow-x-scroll;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  width: 100vw;

  @screen lg {
    width: 100%;
    margin-left: 0;
    padding: 0;
    min-height: 550px;
    overflow: hidden;
  }
}

.pushMeteo {
  background: linear-gradient(160.38deg, #F1C565 -19.5%, #A0CED0 96.26%);
}

.pushSnow {
  background: linear-gradient(159.32deg, #8E9DAD 45.68%, #D5A299 126.22%);
}

.pushTrails {
  background: linear-gradient(199.14deg, rgba(203, 186, 204, 0.8) 1.84%, rgba(37, 128, 179, 0.8) 87.12%);
}

.pushSectorsSelect {
  @apply truncate uppercase;
  font-size: 13px;
  line-height: 24px;
  font-family: var(--font-roboto-condensed);
}

.pushCardBigNumber {
  font-size: 72px;
  line-height: 72px;
  font-weight: 200;

  @screen lg {
    font-size: 96px;
    line-height: 96px;
  }
}