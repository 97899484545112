.header {
  border-color: transparent;
  transition: background-color 0s ease-out;

  .blurMenu {
    width: 100%;
    max-width: 100%;
    // padding: 0 24px;

    // @screen md {
    //   padding: 0 40px;
    // }

    @media (min-width: 1440px) {
      max-width: theme("maxWidth.container");
      // width: calc(100% - (2 * var(--offset)));
    }
  }
  .mainNav {
    @media (max-height: 860px) {
      transition: background-color 0.3s ease-out;
    }
  }
  &.headerMinified {
    @media (max-height: 860px) {
      transition: border 0.3s ease-out;
    }
    border-bottom: 1px solid theme("colors.filet");

    &:not(.headerOpenMenu) {
      color: theme("colors.text.h1");
    }

    .overlay {
      top: calc(var(--header-height) + var(--infobar-height) + 1px);
    }

    .rightPart {
      padding-right: 0;
    }

    .centerPart {
      border-radius: 0;
      border-right: 1px solid theme("colors.filet");
    }
  }

  &.headerFixed {
    border-bottom: none;
    &.headerScrolled {
      border-bottom: 1px solid theme("colors.filet");
    }
  }

  &.headerSubmenuOpened {
    background-color: #fff;
    transition: background-color 0s ease-out;

    &:not(.headerSearchOpened) {
      color: theme("colors.text.h1");

      .topNav {
        color: theme("colors.text.h4");
        border-bottom: 1px solid theme("colors.filet");
      }
      .mainNav {
        border-bottom: 1px solid theme("colors.filet");
      }

      .rightPart {
        padding-right: 0;
      }

      .centerPart,
      .rightPart {
        background: transparent;
      }

      .centerPart {
        border-radius: 0;
        border-right: 1px solid theme("colors.filet");
      }
    }
  }

  &.headerSearchOpened {
    //background-color: theme("colors.text.h1");
  }

  nav {
    @screen lg {
      > a,
      > div button p {
        padding-top: theme("spacing.6");
        padding-bottom: theme("spacing.6");
      }
    }
  }
}

.nav {
  z-index: 2;
}

.mainNavLogo {
  @apply lg:hidden xl:flex;
  width: 140px;
  // max-height: 40px;

  @screen lg {
    width: auto;
    max-height: 48px;
  }
}

.navMenuItem {
  p {
    pointer-events: none;
  }
}

.navSubmenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  visibility: hidden;
  background-color: theme("colors.white");
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: transform 0.2s ease-out, opacity 0.3s ease;
}

.navSearch {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  //background-color: theme("colors.text.h1");
  z-index: 10;
  @screen lg {
    max-height: calc(95vh - 116px);
    overflow: auto;

    @media (max-height: 860px) {
      //padding: theme("spacing.4") 0;
    }
  }
}

.navSearchResults {
  @screen lg {
    @media (max-height: 840px) {
      & > div {
        grid-column: span 2 / span 2;
      }
    }
  }
  position: absolute;
  z-index: -1;
  top: -50px;
  width: 110vw;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  padding: 210px 40px 0;
  a{
    padding: 10px;
  }
  a:first-child{
    padding-left: 20px;
  }
  a:last-child{
    padding-right: 20px;
  }
}

.navSerchResultsButton {
  position: absolute;
  top: 85px;
  background-color: rgb(11, 27, 51);
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  z-index: 1000;
}

.backdropBlur {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: var(--header-height);
  width: 100%;
  transform: translate3d(-50%, 0, 0);
  z-index: -1;
  backdrop-filter: blur(20px);

  width: 100%;

  max-width: 1540px;
  padding: 0px 40px;
  // padding: 0 24px;

  // @screen md {
  //   padding: 0 40px;
  // }

  @media (min-width: 1440px) {
    max-width: calc(theme("maxWidth.container") - 80px);
    // width: calc(100% - (2 * var(--offset)));
  }
}

.menuBurger {
  margin-left: 12px;
}

.menuMobile {
  background-color: #0c1321;
}

.menuMobileBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.menuMobileBottomNav {
  margin-top: auto;
}

.menuMobileNav {
  button[role="menuitem"] {
    &[aria-expanded="true"] {
      svg {
        transform: rotate(-180deg);
      }
      & + ul[role="menu"] {
        max-height: 800px;
        padding-top: theme("spacing.8");
        padding-left: theme("spacing.4");
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        transition: max-height 0.2s ease-in-out;
      }
    }

    svg {
      transition: transform 0.2s ease-in-out;
    }
  }

  ul[role="menu"] {
    max-height: 0;
    transition: max-height 0s ease-in-out;
  }
}

.overlay {
  position: fixed;
  top: calc(var(--header-height) + var(--infobar-height));
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.searchPanel {
  z-index: 2;
  background-color: theme("colors.button.h1");

  @screen lg {
    max-height: 880px;
    max-height: 90vh;
  }
}

.searchPanelContainer {
}
