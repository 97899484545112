.wysiwyg {
  p {
    margin-top: 16px;
    a {
      text-decoration: underline;
    }
  }
  @media (min-width: 1024px) {
    p {
      margin-top: 26px;
      a:hover {
        text-decoration: none;
      }
    }
  }

  :global {
    .arrowLink {
      p {
        margin-top: 0;
      }
    }
  }

  div {
    ul {
      li {
        list-style: disc;
        p {
          &:first-child {
            &:not(:last-child) {
              font-size: 20px;
              line-height: 26px;
              font-family: var(--font-helvetica);
            }
          }
        }
      }
    }
  }
}

.test {
  position: inherit !important;
}

.heroContainer {
  // padding-top: calc(var(--header-height) + var(--infobar-height));
  // padding-top: 24px;
  margin-top: calc(var(--header-height) + var(--infobar-height));
}

.imageContainer {
  height: 100%;
  width: 100%;
  aspect-ratio: 900/584;
  max-height: 60vh;
}

.heroSingle {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
