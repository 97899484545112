.cardGuideLink {
  & > div {
    @screen lg {
      // flex: 0 0 calc(33.3%);
    }
  }
}
.cardTitle {
  @screen lg {
    max-width: 70%;
  }
}

.cardImage {
  padding-top: calc((208/327) * 100%);

  @screen lg {
    // flex: 0 0 calc(33% - 12px);
    flex: 0 0 167px;
    min-height: 190px;
    padding-top: initial;
  }
}