.cardPush {
  width: calc((6 / 7) * 100vw - 48px);
  max-width: 320px;

  @screen lg {
    width: 100%;
    max-width: unset;
  }
}

.cardImage {
  padding-top: calc((170 / 271) * 100%);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background:rgba(11, 27, 51, 0.2);
  }
}