.homeHikingPage {
  $offsetTop : calc(73px + 61px);
  .map {
    height: calc(85vh - 310px);
    &__Container {
      width: 100%;
      height: 100%;
    }
  }
  .list {
  }
  .filter {
    &__active {
      svg {
        fill:red!important;
      }
    }
    &Reset:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
  @media all and (min-width: 1023px) {
    .map {
      height: 100%;
      &__Container {
        position: sticky;
        top: $offsetTop;
        height: calc(100vh - $offsetTop);
        min-width: calc(100vw / 2);
      }
    }
  }
}

.hikingPageFilterbar {
  top: calc(var(--header-height) + var(--infobar-height));
  z-index: 4;
}

.hikingPageContent {
  position: relative;
  z-index: 3;
  background: #ffffff;
}

.positionToggle {
  input {
    &:checked {
      & ~ .box {
        background-color: theme('colors.buttonColor.primary');
      }
      & ~ .dot {
        transform: translateX(100%);
        background-color: theme('colors.white');
      }
    }
  }
}

.homeHikingHeader {
  padding-top: calc(var(--header-height) + var(--infobar-height));
}

.heroSingle {
  height: 312px;
  padding: 0;
  padding-top: calc(var(--header-height) + var(--infobar-height));

  @screen lg {
    height: auto;
    padding-left: theme('spacing.10');
    padding-right: theme('spacing.10');
  }
}

.heroSingleSlide {
  height: 312px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @screen lg {
    height: auto;
    padding-left: 0;

    img {
      position: absolute;
      inset: 0;
    }

    &:nth-child(1) {
      @apply col-span-8 row-span-2 w-full h-full;
      aspect-ratio: 900 / 584;
      max-height: 60vh;
    }
  }
}