.cardAgenda {
  @screen lg {
    width: 100%;
    max-width: unset;
  }
}

.cardImage {
  padding-top: calc((208/327) * 100%);

  &.cardImageLarge {
    @screen lg {
      padding-top: calc((472/674) * 100%);
    }
  }

  @screen lg {
    padding-top: calc((472/445) * 100%);
  }
}