.singlePageTemplate {
  margin-top: calc(var(--header-height) + var(--infobar-height));
}

.singlePageInfobar {
  position: fixed;
  top: calc(var(--header-height) + var(--infobar-height));
  left: 0;
  right: 0;
  transform: translateY(-100%);
  z-index: 11;
  transition: transform 0.3s ease-in-out;

  &.singlePageInfobarOpen {
    .singlePageInfobarHiddenContent {
      max-height: 300px;
    }
  }
  
  &.singlePageInfobarVisible {
    transform: translateY(0);
  }
}

.singlePageInfobarChevron {
  @screen lg {
    display: none !important;
  }
}

.singlePageInfobarHiddenContent {
  transition: max-height 0.3s ease-in-out;
  @screen lg {
    max-height: initial;
  }
}

.rightSidebar {
  > div {
    &:last-child {
      border-bottom: none;
    }
  }
}

.lumiplanModule {
  @apply relative pt-8 text-white;

  @screen lg {
    padding-top: 120px;
  }
}

.drawerPageContent {
  padding-top: calc(var(--header-height) + var(--infobar-height));
}

.drawerBg {
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(#0b1b33, 0.8);
  }

  img {
    object-fit: cover;
  }
}

.weatherModule {
  @apply relative pb-12;
}
.weatherModuleCta {
  @screen lg {
    display: none;
  }
}

.webcamModule {
  @apply relative pb-20;
}
.webcamModuleShortDescription {
  @apply mt-6 pr-6 lg:pr-10 xl:max-w-[392px] xl:pr-4;
  @screen lg {
    margin-top: 16px;
  }
}
.webcamScroller {
  // overflow: hidden;
  // display: grid;
  // grid-auto-flow: row;
  // grid-auto-rows: 80%; /* Each slide covers 80% of the viewport */
  flex-direction: column;
  max-height: 800px;
}

.trailModule {
  @apply relative pb-20;
}