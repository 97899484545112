.sectionMediaFull {
  height: 100vh;

  max-height: 700px;

  &.sectionMediaFullPlaying {
    &::before {
      @apply bg-opacity-0;
    }
  }

  &::before {
    @apply absolute inset-0 bg-black bg-opacity-20;
    content: "";
  }

  .relativeContainer {
    max-height: 700px;

    @screen xl {
      max-height: 800px;
    }
  }

  @screen xl {
    max-height: 800px;
  }
}
