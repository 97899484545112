.sList {
  table, td, th {
    border: 1px solid theme('colors.filet');
    border-collapse: collapse;
  }
}

.sTableBlock {
  @apply py-6 lg:py-14;

}