.hero {
  position: relative;
  height: 560px;
  max-height: 70vh;
  width: 100%;
  z-index: 1;

  @screen xl {
    position: fixed;
    top: 0;
    height: 800px;
    max-height: 80vh;
    min-height: 460px;
  }

  .bannerImage {
    display: none;
  }
}

.heroPlaceholder {
  position: relative;
  height: 560px;
  max-height: 70vh;
  display: none;

  @screen xl {
    display: block;
    height: 800px;
    max-height: 80vh;
    min-height: 460px;
  }

  .bannerImage {
    display: none;
  }
}

.bannerToggle {
}

.staticContent {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.staticTitle {
  display: flex;
  align-items: flex-end;
  height: 100%;

  .bannerImageContent {
    margin-bottom: 32px; // 64px
    z-index: 2;

    @screen lg {
      margin-bottom: 50px; // 122px
    }
  }
}

.positionToggle {
  input {
    &:checked {
      & ~ .box {
        background-color: theme('colors.buttonColor.primary');
      }
      & ~ .dot {
        transform: translateX(100%);
        background-color: theme('colors.white');
      }
    }
  }
}
.mainWeatherIcon {
  max-width: 80px;
}
.mainTemperature {
  font-size: 40px;
}