.card {
  cursor: pointer;
  &:hover {
    .cardImage {
      &::after {
        opacity: 0.3;
      }
    }
  }

  &.cardLarge {
    .cardDescription {
      @screen lg {
        max-width: 60%;
      }
    }
  }
}

.cardImage {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: theme('colors.text.h1');
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cardDescription {
  @apply line-clamp-2;
  max-width: 90%;

  @screen lg {
    max-width: 75%;
  }
}