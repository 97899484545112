.cardsSlider {
  @apply relative;
  
  @screen lg {
    overflow: hidden;
  }
}

.cardsSliderSlider {
  overflow: visible;
}

.emblaSlide {
  &.emblaSlideActivity {
    width: calc((6 / 7) * 100vw - 48px);
    max-width: 320px;

    @screen lg {
      width: 100%;
      max-width: unset;
    }
  }
  @screen lg {
    &:nth-child(1) {
      flex: 0 0 50%;
    }
    &:nth-child(n + 2) {
      flex: 0 0 33.3%;
    }
  }
}

.emblaNavigation {
  @apply hidden lg:block absolute top-0 cursor-pointer;
  height: 80%;
  width: 15%;

  &:hover {
    .emblaNavigationButton {
      opacity: 1;
      &[disabled] {
        opacity: 0.3;
      }
    }
  }

  .emblaNavigationButton {
    @apply absolute top-1/2 transform -translate-y-1/2;
    opacity: 0;
  }
}