.thumbnails {
  @apply pt-16;

  @screen lg {
    padding-top: 120px;
  }
}

.titleImage {
  padding-top: 100%;
  @screen lg {
    top: var(--header-height);
    padding-top: initial;
    max-height: 720px;
    height: 50vw;
  }
}