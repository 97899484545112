.cmsPage {
  // margin-top: calc(var(--header-height) + var(--infobar-height));

  // p {
  //   margin-top: 16px;
  // }
  // @media (min-width: 1024px) {
  //   p {
  //     margin-top: 26px;
  //   }
  // }
  // a {
  //   color: theme('colors.track.red');
  // }
}

.cmsPageContent {
  padding-top: calc(var(--header-height) + var(--infobar-height));
}

.heroSingle {
  height: 312px;
  padding: 0;

  @screen lg {
    height: auto;
    padding-left: theme('spacing.10');
    padding-right: theme('spacing.10');
  }
}

.heroSingleSlide {
  height: 312px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @screen lg {
    height: auto;
    padding-left: 0;

    img {
      position: absolute;
      inset: 0;
    }

    &:nth-child(1) {
      @apply col-span-8 row-span-2 w-full h-full;
      aspect-ratio: 900 / 584;
      max-height: 60vh;
    }
  }
}

.sitemapWrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(20px, auto);

  @screen md {
    grid-template-columns: repeat(2, 1fr);
  }

  @screen lg {
    grid-template-columns: repeat(3, 1fr);
  }
}