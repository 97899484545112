.bannerImage {
  height: 100%;

  &.bannerImageWithToggle {
    .bannerImageContent {
      // margin-bottom: 32px;
      margin-bottom: 64px;

      @screen lg {
        // margin-bottom: 50px;
        margin-bottom: 122px;
      }
    }
  }
}

.bannerImageBackground {
  z-index: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
  
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(11, 27, 51, 0.7) 0%, rgba(11, 27, 51, 0.07) 63.54%)
  }

  img {
    @apply w-full h-full object-cover;
  }
}

.bannerImageContent {
  z-index: 2;
}