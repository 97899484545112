.sFooterNewsletter {
  .sibForm {
    padding: 0;

    .entryField {
      @apply border-none;
      border-bottom: 1px solid theme('colors.filet');
      margin: 0;
      border-radius: 0;
      background-color: transparent;

      input {
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .sibContainer {
    padding: 0 !important;
    background-color: transparent !important;
  }
  .sibFormBlock {
    padding: 0;
    margin-bottom: 8px;
  }
  .sibDropdownCountries {
    max-width: calc(100% - 16px);
    margin-bottom: 0;
  }

  .sibFormDeclaration {
    display: inline-block;
    // align-items: flex-start;
    padding: 0;
  }

  .sibFormBlock {
  }

  .sibFormBlockButton {
    padding: 0;
    min-height: auto;
  }

  .sibSuccessMessage {
    margin-bottom: 16px;
    padding: 0;
  }

  select {
    * {
      color: #000;
    }
  }
}
