.sLinkList {
  button.buttonTrigger {
    &[aria-expanded="true"] {
      svg {
        transform: rotate(-180deg);
      }
      & + ul[role="menu"] {
        max-height: 800px;
        padding-top: theme('spacing.4');
        padding-left: theme('spacing.4');
        padding-bottom: theme('spacing.8');
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        transition: max-height 0.2s ease-in-out;
      }
    }

    svg {
      transition: transform 0.2s ease-in-out;
    }
  }

  ul[role="menu"] {
    max-height: 0;
    transition: max-height 0s ease-in-out;

    @screen lg {
      max-height: initial;
    }
  }
}

.sLinkListChevron {
  @screen lg {
    display: none !important;
  }
}