.cardHiking {
  min-height: 168px;
  @screen lg {
    &:hover {
      .cardHover {
        opacity: 1;
      }
    }
  }
}

.cardHikingThumbnail {
  height: 100%;
  padding-top: calc((210 / 327) * 100%);

  img {
    position: absolute;
    object-fit: cover;
    top: 0;
    // @screen lg {
    // }
  }

  @screen lg {
    padding-top: 0;
    border-radius: 0;
  }
}