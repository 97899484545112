.dropdownTrigger {
  &[aria-expanded="true"] {
    & + .dropdownMenu {
      display:block;
    }
  }
}

.dropdownMenu {
  min-width: 280px;
  max-height: 320px;
  overflow: auto;
  background:#fcfcfc;
}