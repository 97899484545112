.drawerContainer {
  &:nth-child(2) {
    .drawer {
      max-height: calc(100vh - 80px);

      @screen xl {
        height: calc(100vh - 80px);
      }
    }
  }
}
.drawer {
  overflow: scroll;
  max-height: calc(100vh - 80px);

  @screen xl {
    height: calc(100vh - 40px);
  }

  &.drawerWebcam,
  &.drawerTrail {
    overflow: scroll;
    @screen xl {
      align-items: flex-end;
      padding-bottom: 0;
    }
  }
}
.drawerInteractiveMap {
  height: calc(100vh - 80px);
}
.drawerBg {
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(#0b1b33, 0.5);
  }

  img {
    object-fit: cover;
  }
}
.drawerBgMap {
  &::after {
    display: none;
  }
}

.drawerContent {
  @screen xl {
    &.drawerContentWebcam {
      height: calc(100% - 100px);
      @media (min-height: 860px) {
        // height: calc(100% - 146px);
        // height: calc(100%);
        height: calc(100% - 180px);
      }
    }
    &.drawerContentTrail {
      height: calc(100% - 100px);
      @media (min-height: 860px) {
        height: calc(100% - 180px);
      }
    }
  }
}

.drawerContentWebcam {
  > div {
    @screen xl {
      height: calc(100%);
      // align-items: flex-end;
    }
  }
}

.webcamViewer {
  width: 100% !important;
  height: 100% !important;
}

.mainTemperature {
  @apply font-light;
  font-size: 64px;
  line-height: 1.5;
}
.secondaryTemperature {
  @apply font-light text-center;
  font-size: 32px;
  line-height: 1.5;
}

.mainWeatherIcon {
  width: 136px;
  height: 136px;

  @screen xl {
    width: 180px;
    height: 180px;
  }
}
.smallWeatherIcon {
  width: 60px;
  height: 60px;
}

.conditionsTable {
  @apply w-full;
  li {
    @apply flex items-center justify-between mb-2;
  }

  @screen xl {
    @apply opacity-90;
  }
}

/**
  * Webcam
  */
.webcamContainer {
  // min-height: 100%;
  @screen xl {
    // height: calc(100% - 146px);
    scroll-snap-type: y mandatory;
    overflow: scroll;
  }
}
.webcamSlider {
  overflow: visible;
}
.webcamScroller {
  // overflow: hidden;
  // display: grid;
  // grid-auto-flow: row;
  // grid-auto-rows: 80%; /* Each slide covers 80% of the viewport */
  flex-direction: column;
  max-height: 60vh;
}
.leftList {
  @screen xl {
    height: calc(100% - 146px);
  }
}
.desktopSpacer {
  @screen xl {
    flex: 0 0 180px;
    scroll-snap-align: start;
    // height: 146px;
  }
}

.webcamItem {
  aspect-ratio: 327 / 232;
  flex: 0 0 auto;
  max-width: 100%;
  scroll-snap-align: start;

  @screen xl {
    flex: 0 0 auto; /* Adapt slide size to its content */
    min-width: 0;
    max-width: 100%;
    // aspect-ratio: auto;
    aspect-ratio: 900 / 512;
    max-height: 60vh;
  }
}
// .webcamItemImages {
//   pointer-events: none;

//   &::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     background-color: theme('colors.white');
//     opacity: 0;
//     transition: opacity 0.3s ease;
//   }

//   img {
//     position: absolute;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// }

.sliderItem {
  // @apply absolute top-0 w-full h-full;
  // transition-property: transform;
  aspect-ratio: 900 / 512;
  max-height: 60vh;

  &.sliderItemForward {
    &.sliderItemActive {
      z-index: 3;
      transform: translateX(0);

      &.isSliding {
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-duration: 0.8s;
      }
    }
    &.sliderItemNext {
      z-index: 2;
      transform: translateY(100%);
    }
    &.sliderItemPrev {
      z-index: 1;
      transform: translateY(-80%) scale(1.1);

      &.isSliding {
        transition: transform 2s ease-out;
      }
    }
  }

  &.sliderItemBackward {
    &.sliderItemActive {
      z-index: 3;
      transform: translateY(0);

      &.isSliding {
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-duration: 0.8s;
      }
    }
    &.sliderItemNext {
      z-index: 1;
      transform: translateY(80%) scale(1.1);
      &.isSliding {
        transition: transform 2s ease-out;
      }
    }
    &.sliderItemPrev {
      z-index: 2;
      transform: translateY(-100%);
    }
  }
}

.mainNumber {
  @apply font-light;
  margin-right: 6px;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -1px;

  & + p {
    margin-top: 6px;
  }

  @screen xl {
    font-size: 40px;
  }
}

.listItemsContainer {
  min-height: 40px;
  @screen xl {
    min-height: 240px;
  }
}

.poisContainer {
  height: calc(100%);
}

.poisContent {
  min-height: 100%;
}

.poisListSwitcher {
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: theme("colors.text.h1");
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  @screen xl {
    &::before {
      display: none;
    }
  }

  &.isActive {
    &::before {
      opacity: 1;
    }
  }
  &:not(.isActive) {
    @apply text-text-h4;
    @screen xl {
      @apply text-text-h1;
    }
  }
}

.poisList {
  &:not(.isActive) {
    @apply hidden;

    @screen xl {
      @apply block;
    }
  }
}
.poisLine {
  &:nth-child(odd) {
    @apply bg-background-beige;
  }
}
