.modal {
}

.modal__overlay {
}

.modal__content {
  left: theme('spacing.6');
  right: theme('spacing.6');
  margin-bottom: theme('spacing.6');
  width: calc(100% - #{theme('spacing.12')});
  
  @screen lg {
    @apply mb-0;
  }
}