.h1, .h2, .h3,
.h4, .h5, .h6 {
  font-family: var(--font-helvetica);
}
.h1 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  @screen lg {
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -2px;
  }
}

.h2 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -1px;
}

.h3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  @screen lg {
    font-size: 40px;
    line-height: 48px;
  }
}

.h4 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
  @screen lg {
    font-size: 32px;
    line-height: 32px;
  }
}

.h5 {
  font-size: 20px;
  line-height: 26px;
  @screen lg {
    font-size: 24px;
    line-height: 32px;
  }
}

.h6 {
  font-size: 20px;
  line-height: 26px;
}

.nav {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.body1 {
  font-size: 20px;
  line-height: 28px;
}

.body2 {
  font-size: 16px;
  line-height: 24px;
}

.body3 {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02px;
}

@mixin text14 {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02px;
}

.button1 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.button2 {
  @include text14;
  letter-spacing: 0.2px;
}

.button3 {
  @include text14;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.ui1 {
  @include text14;
  letter-spacing: 0.2px;
}

.ui2 {
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family: var(--font-roboto-condensed);
}

.ui3 {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.d--h2 {
  @screen lg {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1px;
  }
}
.d--h3 {
  @screen lg {
    font-size: 40px;
    line-height: 48px;
  }
}