.button {
  @apply inline-block font-medium px-6;
  height: 40px;
  line-height: 38px;
  cursor: pointer;
  letter-spacing: 0.4px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
}

.buttonSmall {
  height: 28px;
  line-height: 26px;
  border-width: 1px;
}

.loading {
  cursor: wait;
}
