@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Helvetica neue", Arial, sans-serif;
  }
  :root {
    /* --font-helvetica: "Helvetica neue"; */
    --foreground: theme("colors.text.h1");
    --background-start-rgb: 255, 255, 255;
    --background-end-rgb: 255, 255, 255;
    --offset: 24px;
    --max_width: 96.25em;
    --max_width_large: 90.97%;
    --columns: 6;
    --gutter: theme("spacing.3");
    --baseline: 8px;
    --baseline-shift: 2rem;
    --color: hsla(204, 80%, 72%, 0.25);
    --color-text: hsla(204, 80%, 72%, 1);
    --media-query: "base";
    --button-primary: 228, 1, 52;
    --season-mode: "winter";
  }
}

@media (min-width: 1024px) {
  :root {
    --columns: 12;
    --gutter: 12px;
    --offset: 40px;
    --color: hsla(286, 51%, 44%, 0.25);
    --color-text: hsla(286, 51%, 44%, 1);
    --media-query: "large";
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground: theme("colors.text.h1");
    --background-start-rgb: 255, 255, 255;
    --background-end-rgb: 255, 255, 255;
  }
}

body {
  color: var(--foreground);
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  font-weight: normal;
}
body.ete {
  --button-primary: 44, 166, 42;
}

body.ete .bg-ete {
  background-color: rgb(44, 166, 42);
}

body.ete .column--dark {
  /* background-color: rgb(44, 166, 42); */
  background-color: #1d1710;
}

body.ete .s-blog-tips {
  background-color: #daf8e6;
}

body.ete footer {
  background-color: #101d15;
}

th {
  font-weight: normal;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.appear-from-top-enter {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}
.appear-from-top-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 200ms, transform 200ms;
}
.appear-from-top-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.appear-from-top-exit-active {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
  transition: opacity 200ms, transform 200ms;
}

.appear-from-bottom-enter {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}
.appear-from-bottom-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 200ms, transform 200ms;
}
.appear-from-bottom-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.appear-from-bottom-exit-active {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: opacity 200ms, transform 200ms;
}

.embla {
  height: 100%;
  overflow: hidden;
  --slide-spacing: 12px;
}
.embla__container {
  display: flex;
  height: 100%;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__scale {
  height: 100%;
  position: relative;
  backface-visibility: hidden;
}
.embla__parallax {
  height: 100%;
  overflow: hidden;
}
.embla__parallax__layer {
  position: relative;
  height: 100%;
  width: 100%;
}

.mobile-overflow-container {
  @apply overflow-x-scroll;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  width: 100vw;
  padding-bottom: 24px;
}

@media (min-width: 1024px) {
  .mobile-overflow-container {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }
}

/** Lenis Style **/
html.lenis {
  height: auto;
}
.lenis.lenis-smooth {
  scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
.lenis.lenis-stopped {
  overflow: hidden;
}

.typeOuverture{
  padding: 8px 12px 7px 12px;
  border: 1px solid #0B1B33;
  background: #0B1B33;
  font-style: normal;
}

.is-fixed {
  position: fixed;
  height: 100%;
  width: 100%;
  /* Allow the main content to be scrolled,
  so we can adjust the scroll position with JS. */
  overflow: auto;
}


[id] {
  scroll-margin-top: 500px;
}

@media (max-width: 600px){
  [id] {
    scroll-margin-top: 200px;
  }
}


