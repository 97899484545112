.calendarStyle {
  padding-top: 2rem;

  border: 0px !important;
  margin: 0 auto;
  @screen lg {
    min-height: 350px;
    padding-bottom: 0.75rem;
    padding-top: 0px;
  }
  :global {
    .react-calendar__navigation {
      border-bottom: 1px solid #d6d6d6;

      @screen lg {
        padding: 0 2.5rem;
        padding-bottom: 2.5rem;
      }
    }

    .react-calendar__navigation__label__labelText {
      font-size: 1.5rem;
      color: theme("colors.text.h1");
      text-transform: capitalize;
    }

    .react-calendar__navigation button {
      font-size: 1.25rem;
      color: theme("colors.text.h1");
      background: none !important;
      &:hover {
      }
      &:disabled {
        background: none;
      }
    }

    .react-calendar__viewContainer {
      padding: 0px theme("spacing.6");
      padding-top: theme("spacing.6");
    }

    .react-calendar__tile {
      font-size: 1rem;
      line-height: 148%;
      color: #202020;
      font-weight: 400;
      // border-radius: 6.2rem;

      &:disabled {
        background: none !important;
        color: #99968e !important;
      }
    }

    .react-calendar__tile--now {
      border-radius: 6.2rem !important;
      background: theme("colors.text.h1");
      color: #fff !important;
      &:hover {
        background: rgba(11, 27, 51, 0.8) !important;
      }
      //   padding: 0px 15px;
    }
    .react-calendar__tile--active {
      background: theme("colors.blueBanner");
      color: #fff;
      position: relative;
      &:enabled {
        &:focus {
          background: theme("colors.blueBanner") !important;
        }
      }
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      // background: #135f7f !important;
    }

    .react-calendar__tile--range {
      background: #e8f5fb;
      border-radius: 0;
      color: #202020;
    }

    .react-calendar__tile--hover {
      background-color: #e8f5fb !important;
      border-radius: 0;
      color: #202020;
    }

    .react-calendar__tile--rangeStart {
      // background: #135f7f !important;
      border-radius: 0;
      color: #fff;
      border-radius: 100px;
      position: relative;
      &::before {
        position: absolute;
        width: 3rem;
        height: 3rem;
        content: "";
        background: theme("colors.blueBanner");
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // z-index: -1;
      }

      abbr {
        position: inherit;
        z-index: 2;
      }
    }

    .react-calendar__tile--rangeEnd {
      // background: #135f7f !important;
      font-weight: 400;
      border-radius: 6.25rem;
      color: #fff;

      position: relative;
      &::before {
        position: absolute;
        width: 3rem;
        height: 3rem;
        content: "";
        background: #135f7f;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      abbr {
        position: inherit;
        z-index: 2;
      }
    }

    // react-calendar__tile
    //   react-calendar__tile--active
    //   react-calendar__tile--range
    //   react-calendar__tile--rangeEnd
    //   react-calendar__month-view__days__day
    .react-calendar__month-view__weekdays__weekday {
      font-size: 0.875rem !important;
      color: #99968e;
      font-family: var(--font-helvetica);
      font-weight: 400;
      abbr:where([title]) {
        text-decoration: none;
      }
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background: #e8f5fb;
    }
  }
}
.mobileDateDisplay {
  border-radius: 48px;
}

.calendarContainer {
  min-height: 450px;
}
