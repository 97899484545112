.doubleImageSlider {
  @apply overflow-hidden;
}

.firstImage {
  flex: 0 0 calc(83% - 12px);
  max-width: 500px;

  .cardImage {
    padding-top: calc((440/330) * 100%);
  }

  @screen lg {
    max-width: initial;
  }
}

.secondImage {
  flex: 0 0 60%;
  max-width: 350px;

  .cardImage {
    padding-top: calc((250/184) * 100%);

    @screen lg {
      padding-top: calc((736/560) * 100%);
    }
  }

  @screen lg {
    max-width: initial;
  }
}

.sliderItem {
  @apply absolute top-0 w-full h-full;
  transition-property: transform;

  &.sliderItemForward {
    &.sliderItemActive {
      z-index: 3;
      transform: translateX(0);
  
      &.isSliding {
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-duration: 0.8s;
      }
    }
    &.sliderItemNext {
      z-index: 2;
      transform: translateX(100%);
    }
    &.sliderItemPrev {
      z-index: 1;
      transform: translateX(-100%);
  
      &.isSliding {
        
        transition: transform 2s ease-out;
      }
    }
  }

  &.sliderItemBackward {
    &.sliderItemActive {
      z-index: 3;
      transform: translateX(0);
  
      &.isSliding {
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-duration: 0.8s;
      }
    }
    &.sliderItemNext {
      z-index: 1;
      transform: translateX(100%);
      &.isSliding {
        transition: transform 2s ease-out;
      }
    }
    &.sliderItemPrev {
      z-index: 2;
      transform: translateX(-100%);
    }
  }


  &.isSliding {
  }
}

.cardImage {
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: theme('colors.white');
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}