.navLink {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 4px;
    background: theme("colors.buttonColor.primary");
    bottom: 0;
    width: 100%;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      transform-origin: left;

      transform: scaleX(1);
    }
  }
  &.active {
    color: theme("colors.buttonColor.primary");
    &::after {
      transform: scaleX(1);
    }
  }
}
