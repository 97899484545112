.sParagraph {
  > p {
    margin-bottom: theme("spacing.4");
  }
  ul {
    padding-left: 40px;
    li {
      list-style: disc;
      margin-top: theme("spacing.4");
      &:first-child {
        margin-top: theme("spacing.6");
      }
      &:last-child {
        margin-bottom: theme("spacing.10");
      }
      // p {
      //   &:first-child {
      //     &:not(:last-child) {
      //       font-size: 20px;
      //       line-height: 26px;
      //       font-family: var(--font-helvetica);
      //       margin-bottom: theme("spacing.4");
      //     }
      //   }
      // }
    }
  }
}
