.colorContrastBlock {
  contain: paint;
  & + div {
    // Handle the beige relief overflow
    // Overflow hidden here would break the sticky behavior
    z-index: 1;
  }
}

.colorContrastBlockContainer {
  @screen lg {
    padding-top: 120px;
  }
}

.backgroundDark {
  background-color: theme("colors.text.h1");
  color: theme("colors.white");
}

.stickyAside {
  @screen lg {
    top: calc(var(--header-height) + theme("spacing.8"));
  }
}

.darkBgPattern {
  z-index: 1;
  position: absolute;
  inset: 0;
  background-repeat: repeat;
  background-size: 500px;
  background-position: center center;
  // background-size: cover;
}

.darBgRelief {
  @screen lg {
    margin-top: -140px;
  }
}

.beigeRelief {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -300%;
    width: 100%;
    height: 300%;
    background-color: #f5f2ee;
    z-index: 1;
  }
}

.blockImage {
  padding-top: calc((397 / 327) * 100%);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: theme('colors.white');
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @screen lg {
    padding-top: calc((397 / 327) * 100%);
  }
}