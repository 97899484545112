.infoBar {
  min-height: 32px;
  z-index: 1;

  &.infoBarFixed {
    top: var(--header-height);
    z-index: 20;

    @screen lg {
      top: 0;

      & + header {
        top: var(--infobar-height);
      }
    }
  }

  &.infoBarWarning {
    p {
      @screen xl {
        white-space: nowrap;
        will-change: transform;
        transform: translateX(90%);
        animation: marquee 30s linear infinite;
      }
    }
  }

  &.infoBarWarningPaused {
    > div,
    > a {
      p {
        @screen xl {
          animation-play-state: paused;
        }
      }
    }
  }
}

.infoBarItem {
}

@keyframes marquee {
  0% {
    -moz-transform: translateX(90%);
    -webkit-transform: translateX(90%);
    transform: translateX(90%);
  }
  100% {
    -moz-transform: translateX(-90%);
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%);
  }
}
