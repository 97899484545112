.sChampionsBlock {
  @apply py-10;
  overflow: visible;

  @screen lg {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.championsBlockList {
  @screen lg {
    @apply flex-row;

    :global {
      .cardChampion {
        flex: 0 0 calc(33.3%);
        
        &:first-child {
          flex: 0 0 calc(50%);

          .cardImage {
            padding-top: calc((552/674) * 100%);
          }
        }
      }
    }
  }
}

.emblaNavigation {
  @apply hidden lg:block absolute top-0 cursor-pointer;
  height: 90%;
  width: 15%;
  z-index: 2;

  &:hover {
    .emblaNavigationButton {
      opacity: 1;
      &[disabled] {
        opacity: 0.3;
      }
    }
  }

  .emblaNavigationButton {
    @apply absolute top-1/2 transform -translate-y-1/2;
    opacity: 0;
  }
}
