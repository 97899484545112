// @import './utils/fonts.scss';
@import './utils/variables.scss';

:root {
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns: repeating-linear-gradient(
    to right,
    var(--color),
    var(--color) var(--column-width),
    transparent var(--column-width),
    transparent var(--repeating-width)
  );
  --background-baseline: repeating-linear-gradient(
    to bottom,
    var(--color),
    var(--color) 1px,
    transparent 1px,
    transparent var(--baseline)
  );
  --header-height: 72px;
  --infobar-height: 0;
}

.container {
  width: 100%;
  max-width: 100%;
  // padding: 0 24px;

  // @screen md {
  //   padding: 0 40px;
  // }
  
  @media (min-width: 1440px) {
    max-width: theme('maxWidth.container');
    // width: calc(100% - (2 * var(--offset)));
  }
}

body {
  position: relative;
  &.grid-visible {
    &::before {
      position: fixed;
      top: 0; right: 0; bottom: 0; left: 0;
      padding: 0 24px;
      margin-right: auto;
      margin-left: auto;
      width: calc(100% - (2 * var(--offset)));
      max-width: var(--max_width);
      min-height: 100vh;
      content: '';
      background-image: var(--background-columns), var(--background-baseline);  
      background-size: var(--background-width) 100%;
      background-position: 0 var(--baseline-shift);
      z-index: 1000;
      pointer-events: none;

      @media (min-width: 1440px) {
        max-width: calc(theme('maxWidth.container') - (2 * var(--offset)));
        padding: 0 40px;
      }
    }
  }
}

.bg-blur {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
}

// img[alt~="missing"] {
//   border: 3px solid red;
// }
