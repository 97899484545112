.cardActivity {}

.cardImage {
  padding-top: calc((288/271) * 100%);

  &.cardImageLarge {
    @screen lg {
      padding-top: calc((472/674) * 100%);
    }
  }
}

.cardDescription {
  max-width: 330px;
}