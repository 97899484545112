.column {
  @apply py-10 lg:py-14;
}

.columnDarkBg {
  @apply bg-text-h1 text-white pb-0 lg:pb-0;

  &:not(:last-child) {
    @apply lg:mb-12;
  }
}