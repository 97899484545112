.cardStay {
  width: calc((6 / 7) * 100vw - 48px);
  max-width: 320px;

  @screen lg {
    width: 100%;
    max-width: unset;
  }
}

.cardImage {
  padding-top: calc((336/271) * 100%);
}