.faqItem {
  button {
    &[aria-expanded="true"] {
      &:hover {
        svg {
          transform: rotate(-180deg);
        }  
      }
      svg {
        transform: rotate(-180deg);
      }
      & + .faqItemContent {
        max-height: 400px;
        // padding-top: theme('spacing.4');
        // padding-left: theme('spacing.0');
        padding-bottom: theme('spacing.4');
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        transition: max-height 0.2s ease-in-out;
      }
    }

    svg {
      transition: transform 0.2s ease-in-out;
    }
  }

  .faqItemContent {
    max-height: 0;
    transition: max-height 0s ease-in-out;
  }

  .faqItemIcon {
    position: relative;
    svg {
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;

      // &:nth-child(2) {
      //   position: absolute;
      //   top: 0;
      //   transform: translate3d(-6px, 6px, 0) scale(0.8);
      //   opacity: 0;
      // }
    }
  }

  @screen lg {
    &:hover {
      .faqItemIcon {
        svg {
          transform: translate3d(-6px, 0px, 0);

          // &:nth-child(1) {
          //   transform: translate3d(6px, -6px, 0) scale(0.8);
          //   opacity: 0;
          // }
          // &:nth-child(2) {
          //   transform: translate3d(0, 0, 0) scale(0.8);
          //   opacity: 1;
          // }
        }
      }
    }
  }
}