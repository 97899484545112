.arrowLink {
  max-width: 600px;
  &:hover {
    .arrowLinkIconHover {
      transform: translate3d(5px, 0, 0) scale(1);
      opacity: 1;
    }
    .arrowLinkIcon {
      // transform: translate3d(5px, 0, 0);
      //transform: scale(0.8);
      //opacity: 0;
    }
    p {
      //transform: translateX(-16px);
    }
  }

  p {
    transition: transform 0.2s ease;
  }
  &reverse {
    &:hover,
    &.arrowLinkActive {
      .arrowLinkIconHover {
        //transform: translate3d(16px, 0, 0) scale(1);
        //opacity: 1;
      }
      .arrowLinkIcon {
        // transform: translate3d(-5px, 0, 0);
        //transform: scale(0.8);
        //opacity: 0;
      }
      p {
        //transform: translateX(24px);
      }
    }
  }
}

.arrowLinkIcon {
  margin-left: 8px;
  margin-right: 8px;
  transition: transform 0.15s ease, opacity 0.15s ease;
  min-width: 20px;
}

.arrowLinkIconHover {
  //margin-left: -16px;
  //opacity: 0;
  //transform: translate3d(-8px, 0, 0) scale(0.8);
  //transition: opacity 0.25s ease, transform 0.25s;
}
