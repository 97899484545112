.navItemParent {
  &[aria-expanded="true"] {
    @apply bg-buttonColor-primary text-white;

    & + div[role="menu"] {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0px, 0);
      transition: transform 0.2s ease-out, opacity 0.3s ease;
    }
  }
}

.navSubmenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  visibility: hidden;
  background-color: theme("colors.white");
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: transform 0.2s ease-out, opacity 0.3s ease;
  border-bottom: 1px solid #D9D9DB;
}
