.cardSearch {
}

.cardImage {
  width: 102px;
  height: 108px;
  // min-width: calc((2 / 6) * 100vw - 24px);
  // max-width: 320px;
  // padding-top: 100%;

  @screen lg {
    width: 100%;
    height: auto;
    padding-top: calc(352 / 330 * 60%);
  }
}

.cardContent {
  width: calc(100% - 100px);

  @screen lg {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
