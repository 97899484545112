.singlePageTemplate {
  margin-top: calc(var(--header-height) + var(--infobar-height));
}
.scrollbar::-webkit-scrollbar {
    display: none;
}

.scrollbar {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  overflow-y: scroll;
  
  &.scrollbarScrolled {
    .scrollbarShadowBefore {
      opacity: 1;
    }
  }
  &.scrollbarScrolledEnd {
    .scrollbarShadowAfter {
      opacity: 0;
    }
  }

  @screen lg {
    padding-top: 120px;

    &:before,
    &:after {
      position: fixed;
    }

    &:before {
      top: calc(var(--header-height) + var(--infobar-height) + 1px);  
    }
  }
}

.scrollbarShadow {
  @apply absolute inline-block left-0 h-16 pointer-events-none opacity-0 z-2;
  content: "";

  margin-left: -24px;
  width: calc(100% + 48px);

  @screen lg {
    @apply w-full ml-0;
  }
}

.scrollbarShadowBefore {
  @apply top-0;
  background: linear-gradient(180deg, rgba(11, 27, 51,1) 10%, rgba(11, 27, 51,0) 100%);
}

.scrollbarShadowAfter {
  @apply bottom-0 opacity-100;
  background: linear-gradient(0deg, rgba(11, 27, 51,1) 10%, rgba(11, 27, 51,0) 100%);
}

.singlePageInfobar {
  position: fixed;
  top: calc(var(--header-height) + var(--infobar-height));
  left: 0;
  right: 0;
  transform: translateY(-100%);
  z-index: 11;
  transition: transform 0.3s ease-in-out;

  &.singlePageInfobarOpen {
    .singlePageInfobarHiddenContent {
      max-height: 300px;
    }
  }
  
  &.singlePageInfobarVisible {
    transform: translateY(0);
  }
}

.singlePageInfobarChevron {
  @screen lg {
    display: none !important;
  }
}

.singlePageInfobarHiddenContent {
  transition: max-height 0.3s ease-in-out;
  @screen lg {
    max-height: initial;
  }
}

.rightSidebar {
  > div {
    &:last-child {
      border-bottom: none;
    }
  }
}

.lumiplanModule {
  @apply relative text-white;

  // @screen lg {
  //   padding-top: 120px;
  // }
}

.drawerPageContent {
  padding-top: calc(var(--header-height) + var(--infobar-height));
}

.drawerBg {
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(#0b1b33, 0.8);
  }

  img {
    object-fit: cover;
  }
}

.weatherModule {
  @apply relative pb-4;
}
.weatherModuleCta {
  @screen lg {
    display: none;
  }
}


.podcastItemContainer{
  flex-shrink: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  border-radius: 16px; 
}

.controls{
  background-color: white;
  border-radius: 40px;
  width: 12px;
}

.details{
  max-width: 304px !important;

  @screen lg {
    padding-top: 120px;
  }
}

.wrapper{
  background-color: red !important;
}

.wavesurferOpacity{
  height: 51px;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: gray;
  opacity: 0.7;
  z-index: 2;
}

.wavesurferOpacity::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px; /* Adjust the height of the gradient border */
  background: linear-gradient(to bottom, #0B1B33, #0B1B33);
}